import type { Settings as LayoutSettings } from '@ant-design/pro-layout';
import { PageLoading } from '@ant-design/pro-layout';
import type { RequestConfig, RunTimeLayoutConfig } from 'umi';
import { history } from 'umi';
import RightContent from '@/components/RightContent';
import Footer from '@/components/Footer';
import { Image, notification } from 'antd';
import Qs from 'qs';
import type { RequestOptionsInit, ResponseError } from 'umi-request';
import { currentUser as queryCurrentUser, getCurrentUserPermissions } from '@/services/user/api';
import { ADMIN, loginPath } from '@/constants';
import { toLogin } from '@/pages/user/Login';
import { AccessList } from '@@/plugin-generate-access/accessIds';
import { getAuthToken } from '@/utils/storage';
// import { ErrorShowType } from '@@/plugin-request/request';
import { getEnv, isShowSelectEnv } from './utils/changeEnv';
import { isObject } from 'lodash';
import type { APPs } from '@@/plugin-generate-access/accessUtil';
import { isYinshan } from '@@/plugin-generate-access/accessUtil';
// import { getCompliance } from './pages/system/config/compliance/service';

const isDev = process.env.NODE_ENV === 'development';

if (!isDev) {
  // Output build time to determine if it has completed updating
  console.log(
    `%clast build time:${buildTime}`,
    'color: #1890ff;font-size: 24px;font-weight: bold;text-decoration: underline;',
  );
  console.log(
    `%clast commit meg:${lastCommitMsg}`,
    'color: #1890ff;font-size: 24px;font-weight: bold',
  );
}

/** Display a loading indicator when fetching user information takes a while */
export const initialStateConfig = {
  loading: <PageLoading />,
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
  app: APPs;
  isComplianceOn?: boolean;
  getSomeDomain?: (key: string) => boolean;
  getLang: string[];
}> {
  const fetchUserInfo = async () => {
    try {
      const data = {} as API.CurrentUser;
      const { data: user } = await queryCurrentUser();
      if (user.id) {
        const { data: userPermissions } = await getCurrentUserPermissions(user.id);
        if (userPermissions && userPermissions.length > 0) {
          const admin = userPermissions.find((v) => v.name === ADMIN);
          data.isAdmin = !!admin;
          if (admin) {
            data.permissions = AccessList.map((value) => {
              return {
                name: value,
              };
            });
          } else {
            data.permissions = userPermissions;
          }
        } else {
          data.permissions = [];
        }
      }
      data.user = user;
      return data;
    } catch (error) {
      // history.push(loginPath);
    }
    return undefined;
  };

  const defaultGetLang_contain_CN: string[] = ['en-US', 'zh-CN'];
  const defaultGetLang: string[] = ['en-US'];

  // If it's the login page, do not execute
  const token = getAuthToken();

  const getLangValue = () => {
    if (isDev || location.origin.indexOf('pr-') > -1) {
      return defaultGetLang_contain_CN;
    }
    return defaultGetLang;
  };

  if (token && history.location.pathname !== loginPath) {
    const currentUser = await fetchUserInfo();
    // const compliance = await getCompliance().catch(() => 'INACTIVE');
    return {
      fetchUserInfo,
      currentUser,
      settings: {},
      isComplianceOn: false,
      getSomeDomain: (domainKey: string) => {
        return location.origin.indexOf(domainKey) > -1;
      },
      getLang: getLangValue(),
    };
  }
  return {
    fetchUserInfo,
    settings: {},
    getSomeDomain: (domainKey: string) => {
      return location.origin.indexOf(domainKey) > -1;
    },
    getLang: getLangValue(),
  };
}

function filterMenus(routes: any[], app: string, isComplianceOn?: boolean) {
  const removes: any[] = [];
  routes.map((route: any, index) => {
    const isOtherProduct = route.app && app && route.app.trim().toLowerCase() !== app;
    const isHide =
      isComplianceOn &&
      (route.hideWhenComplianceIsOn === true ||
        (typeof route.hideWhenComplianceIsOn === 'string' &&
          route.hideWhenComplianceIsOn.trim().toLowerCase() === app));
    if (isHide) {
      route.hideInMenu = true;
    }
    if (isOtherProduct) {
      removes.push(index);
    }
    route.children = route.routes;
    if (route.routes) {
      filterMenus(route.routes, app, isComplianceOn);
    }
  });
  removes.reverse().forEach((value) => {
    routes.splice(value, 1);
  });
}

// API supported by ProLayout: https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  return {
    logo: () => <Image src={`/icons/logo.png`} preview={false} />,
    rightContentRender: () => {
      // @ts-ignore
      return <RightContent app={initialState?.app} lang={initialState?.getLang} />;
    },
    disableContentMargin: false,
    waterMarkProps: {
      content: initialState?.currentUser?.user?.phone,
    },
    menuDataRender: isYinshan
      ? (menuData) => {
          filterMenus(menuData, initialState!.app, initialState?.isComplianceOn);
          return menuData;
        }
      : undefined,
    footerRender: () => <Footer productionName={`HAPPYCASH`} />,
    onPageChange: () => {
      const { location } = history;
      // If not logged in, redirect to login
      if (!initialState?.currentUser && location.pathname !== loginPath) {
        history.push(loginPath);
      }
    },
    // For OpenAPI, use the backend address directly: https://dev.gateway.yinshantech.cn/webjars/swagger-ui/index.html?configUrl=%2Fv3%2Fapi-docs%2Fswagger-config&urls.primaryName=ap-internal#/
    // links: isDev
    //   ? [
    //       <Link to="/umi/plugin/openapi" target="_blank" key="open API">
    //         <LinkOutlined />
    //         <span>OpenAPI 文档</span>
    //       </Link>,
    //       <Link to="/~docs" key="docs">
    //         <BookOutlined />
    //         <span>业务组件文档</span>
    //       </Link>,
    //     ]
    //   : [],
    menuHeaderRender: undefined,
    // Custom 403 page
    // unAccessible: <div>unAccessible</div>,
    ...initialState?.settings,
  };
};

// request config
const authHeaderInterceptor = (url: string, options: RequestOptionsInit) => {
  const token = getAuthToken();
  // If REACT_APP_ENV is dev, it indicates that the local proxy environment is dev
  // getEnv indicates switching to dev environment during build pr
  const isDevEnv = REACT_APP_ENV
    ? REACT_APP_ENV === 'dev'
    : isShowSelectEnv() && getEnv() === 'dev';
  const devHeaders = {
    'X-USER-ID': 1,
  };
  // Remove empty values from query parameters
  if (options.params) {
    Object.entries(options.params).map((item) => {
      if (['', ' ', null, undefined].includes(item[1])) {
        delete options?.params?.[item[0]];
      }
    });
  }
  if (token && !url.includes('/user/login')) {
    return {
      url: `${url}`,
      options: {
        timeout: 2 * 60 * 1000, // For some backend interfaces, optimization is currently not possible. It has to be set to 2 minutes as required by the backend
        ...options,
        interceptors: true,
        headers: {
          ...options.headers,
          'x-auth-token': token,
          // If it's dev, add X-USER-ID=1 to be compatible with dev gateway
          ...(isDevEnv ? devHeaders : {}),
        },
      } as RequestOptionsInit,
    };
  }
  return { url, options };
};

export const request: RequestConfig = {
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'repeat' }),
  async errorHandler(error: ResponseError) {
    // Unknown problem: error.request is sometimes nonexistent
    const { response, request: requestInfo } = error;
    let responseData: API.Response = {} as API.Response;
    console.dir(error);
    try {
      responseData = await response.json();
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (error) {}

    // @ts-ignore
    if (error?.request?.options?.skipErrorHandler) {
      throw error;
    }

    if (response && response.status) {
      const { status } = response;
      if (status === 401) {
        toLogin(true);
        return;
      }
    }
    if (error.name === 'BizError' || response) {
      notification.error({
        description: (
          <div>
            <div>{requestInfo?.url}</div>
            <div>{responseData?.message || error?.message}</div>
          </div>
        ),
        message: response?.status || 'Request Error',
      });
    } else {
      notification.error({
        description:
          error.message || 'Your network is abnormal, and you cannot connect to the server',
        message: 'Network Anomaly',
      });
    }

    console.error(error);
    throw error;
  },
  errorConfig: {
    adaptor: (resData: any) => {
      if (resData && isObject(resData as Record<string, any>) && 'success' in resData) {
        return {
          ...resData,
          errorCode: resData?.code,
          errorMessage: resData?.message,
        };
      }
      return {
        success: true,
        data: resData,
      };
    },
  },
  requestInterceptors: [authHeaderInterceptor],
};
