// @ts-nocheck
import access_0 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/system/privilege/user/access'
import access_1 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/system/privilege/reviewUser/access'
import access_2 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/system/privilege/collection-user/access'
import access_3 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/system/privilege/role/access'
import access_4 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/system/privilege/path/access'
import access_5 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/system/privilege/permission/access'
import access_6 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/system/privilege/group/access'
import access_7 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/system/noticeManage/access'
import access_8 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/userManage/userFeedback/access'
import access_9 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/userManage/userList/access'
import access_10 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/userManage/userListDetail/access'
import access_11 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/userManage/repaymentCode/access'
import access_12 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/operation/channelManage/access'
import access_13 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/my/access'
import access_14 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/allCase/access'
import access_15 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/mydetail/access'
import access_16 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/specialDivision/access'
import access_17 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/divisionRecord/access'
import access_18 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/configManage/remissionPlanConfig/access'
import access_19 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/configManage/queueManage/access'
import access_20 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/configManage/groupQueueManage/access'
import access_21 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/configManage/messageTemplate/access'
import access_22 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/outCallsManage/providerConfig/access'
import access_23 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/outCallsManage/taskConfig/access'
import access_24 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/outCallsManage/taskAssociation/access'
import access_25 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/outCallsManage/taskPush/access'
import access_26 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/outCallsManage/callList/access'
import access_27 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/outCallsRecords/BLURecords/access'
import access_28 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/ApproveCenter/access'
import access_29 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/workSchedule/detail/access'
import access_30 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/workSchedule/changeLog/access'
import access_31 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/mobiles/access'
import access_32 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/contactMobiles/access'
import access_33 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/blacklist/access'
import access_34 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/routerTest/access'
import access_35 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/collection/monitor/urgencyRate/access'
import access_36 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/creditReview/FirstReview/auditReview/access'
import access_37 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/creditReview/FirstReview/auditedReview/access'
import access_38 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/creditReview/FirstReview/manualAuditStatistics/access'
import access_39 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/creditReview/withdrawalReview/auditReview/access'
import access_40 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/creditReview/withdrawalReview/auditedReview/access'
import access_41 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/creditReview/withdrawalReview/withdrawalDetail/access'
import access_42 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/creditReview/withdrawalReview/withdrawalStatistics/access'
import access_43 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/creditReview/creditRecordManager/access'
import access_44 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/creditReview/creditRecordDetail/access'
import access_45 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/creditReview/withdrawManage/access'
import access_46 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/creditReview/changeOrderStatus/access'
import access_47 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/creditReview/AntiFraud/access'
import access_48 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/creditReview/AntiFraudDetail/access'
import access_49 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/developer/indicatorManagement/indicationManage/access'
import access_50 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/developer/indicatorManagement/modelManage/access'
import access_51 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/developer/indicatorManagement/datasourceManage/access'
import access_52 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/developer/indicatorManagement/scriptManage/access'
import access_53 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/developer/indicatorManagement/groupManage/access'
import access_54 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/developer/indicatorManagement/indicatorGroupManage/access'
import access_55 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/loanManage/loanRecords/access'
import access_56 from '/codebuild/output/src4125279433/src/happycash-admin/src/pages/riskManage/blacklist/access'


const accessSet = new Set();

function generateAccess<N extends string, C extends readonly string[]>(access: {
  id: N,
  components?: C
}):
  { [k in `${N}_components_${C[number]}`]: `${N}_components_${C[number]}`} &
  {
    [k in N]: N;
  } {
  if (accessSet.has(access.id)) {
    throw new Error(`Duplicate access id "${access.id}",please check`);
  }
  accessSet.add(access.id)
  const result: any = { [access.id]: access.id };
  if (Array.isArray(access.components)) {
    access.components.forEach(value => {
      result[`${access.id}_components_${value}`] = `${access.id}_components_${value}`
    })
  }
  return result
}


export const RouteAccessMap = {
'/system/privilege/user:-0-2-1-1':[access_0.id],
'/system/privilege/reviewUser:-0-2-1-2':[access_1.id],
'/system/privilege/collection-user:-0-2-1-3':[access_2.id],
'/system/privilege/role:-0-2-1-4':[access_3.id],
'/system/privilege/path:-0-2-1-5':[access_4.id],
'/system/privilege/permission:-0-2-1-6':[access_5.id],
'/system/privilege/group:-0-2-1-7':[access_6.id],
'/system/privilege:-0-2-1':[access_0.id,access_1.id,access_2.id,access_3.id,access_4.id,access_5.id,access_6.id],
'/system/noticeManage:-0-2-2':[access_7.id],
'/system:-0-2':[access_0.id,access_1.id,access_2.id,access_3.id,access_4.id,access_5.id,access_6.id,access_7.id],
'/userManage/userFeedback:-0-3-1':[access_8.id],
'/userManage/userList:-0-3-2':[access_9.id],
'/userManage/userListDetail:-0-3-3':[access_10.id],
'/userManage/repaymentCode:-0-3-4':[access_11.id],
'/userManage:-0-3':[access_8.id,access_9.id,access_10.id,access_11.id],
'/operation/channelManage:-0-4-0':[access_12.id],
'/operation:-0-4':[access_12.id],
'/collection/my:-0-5-1':[access_13.id],
'/collection/allCase:-0-5-2':[access_14.id],
'/collection/my/caseDetail/:customerId:-0-5-3':[access_15.id],
'/collection/specialDivision:-0-5-4':[access_16.id],
'/collection/divisionRecord:-0-5-5':[access_17.id],
'/collection/config/remissionPlanConfig:-0-5-6-1':[access_18.id],
'/collection/config/queueManage:-0-5-6-2':[access_19.id],
'/collection/config/groupQueueManage:-0-5-6-3':[access_20.id],
'/collection/config/messageTemplate:-0-5-6-4':[access_21.id],
'/collection/config:-0-5-6':[access_18.id,access_19.id,access_20.id,access_21.id],
'/collection/outCallsManage/providerConfig:-0-5-7-0':[access_22.id],
'/collection/outCallsManage/taskConfig:-0-5-7-1':[access_23.id],
'/collection/outCallsManage/taskAssociation:-0-5-7-2':[access_24.id],
'/collection/outCallsManage/taskPush:-0-5-7-3':[access_25.id],
'/collection/outCallsManage/callList:-0-5-7-4':[access_26.id],
'/collection/outCallsManage:-0-5-7':[access_22.id,access_23.id,access_24.id,access_25.id,access_26.id],
'/collection/outCallsRecords/BLURecords:-0-5-8-0':[access_27.id],
'/collection/outCallsRecords:-0-5-8':[access_27.id],
'/collection/ApproveCenter:-0-5-9':[access_28.id],
'/collection/workSchedule/detail:-0-5-10-0':[access_29.id],
'/collection/workSchedule/changeLog:-0-5-10-1':[access_30.id],
'/collection/workSchedule:-0-5-10':[access_29.id,access_30.id],
'/collection/mobiles:-0-5-11':[access_31.id],
'/collection/contactMobiles:-0-5-12':[access_32.id],
'/collection/blacklist:-0-5-13':[access_33.id],
'/collection/routerTest:-0-5-14':[access_34.id],
'/collection/monitor/urgencyRate:-0-5-15-0':[access_35.id],
'/collection/monitor:-0-5-15':[access_35.id],
'/collection:-0-5':[access_13.id,access_14.id,access_15.id,access_16.id,access_17.id,access_18.id,access_19.id,access_20.id,access_21.id,access_22.id,access_23.id,access_24.id,access_25.id,access_26.id,access_27.id,access_28.id,access_29.id,access_30.id,access_31.id,access_32.id,access_33.id,access_34.id,access_35.id],
'/creditReview/FirstReview/auditReview:-0-6-1-1':[access_36.id],
'/creditReview/FirstReview/auditedReview:-0-6-1-2':[access_37.id],
'/creditReview/FirstReview/manualAuditStatistics:-0-6-1-3':[access_38.id],
'/creditReview/FirstReview:-0-6-1':[access_36.id,access_37.id,access_38.id],
'/creditReview/withdrawalReview/auditReview:-0-6-2-1':[access_39.id],
'/creditReview/withdrawalReview/auditedReview:-0-6-2-2':[access_40.id],
'/creditReview/withdrawalReview/withdrawalDetail:-0-6-2-3':[access_41.id],
'/creditReview/withdrawalReview/withdrawalStatistics:-0-6-2-4':[access_42.id],
'/creditReview/withdrawalReview:-0-6-2':[access_39.id,access_40.id,access_41.id,access_42.id],
'/creditReview/creditRecordManager:-0-6-3':[access_43.id],
'/creditReview/creditRecordDetail:-0-6-4':[access_44.id],
'/creditReview/withdrawManage:-0-6-5':[access_45.id],
'/creditReview/changeOrderStatus:-0-6-6':[access_46.id],
'/creditReview/AntiFraud:-0-6-7':[access_47.id],
'/creditReview/AntiFraud/detail:-0-6-8':[access_48.id],
'/creditReview:-0-6':[access_36.id,access_37.id,access_38.id,access_39.id,access_40.id,access_41.id,access_42.id,access_43.id,access_44.id,access_45.id,access_46.id,access_47.id,access_48.id],
'/developer/indicatorManagement/indicationManage:-0-7-1-0':[access_49.id],
'/developer/indicatorManagement/modelManage:-0-7-1-1':[access_50.id],
'/developer/indicatorManagement/datasourceManage:-0-7-1-2':[access_51.id],
'/developer/indicatorManagement/scriptManage:-0-7-1-3':[access_52.id],
'/developer/indicatorManagement/groupManage:-0-7-1-4':[access_53.id],
'/developer/indicatorManagement/indicatorGroupManage:-0-7-1-5':[access_54.id],
'/developer/indicatorManagement/:-0-7-1':[access_49.id,access_50.id,access_51.id,access_52.id,access_53.id,access_54.id],
'/developer:-0-7':[access_49.id,access_50.id,access_51.id,access_52.id,access_53.id,access_54.id],
'/loanManage/loanRecords:-0-8-1':[access_55.id],
'/loanManage:-0-8':[access_55.id],
'/riskManage/blacklist:-0-9-1':[access_56.id],
'/riskManage:-0-9':[access_56.id],
}


export const AccessIds = {
        ...generateAccess(access_0),
        ...generateAccess(access_1),
        ...generateAccess(access_2),
        ...generateAccess(access_3),
        ...generateAccess(access_4),
        ...generateAccess(access_5),
        ...generateAccess(access_6),
        ...generateAccess(access_7),
        ...generateAccess(access_8),
        ...generateAccess(access_9),
        ...generateAccess(access_10),
        ...generateAccess(access_11),
        ...generateAccess(access_12),
        ...generateAccess(access_13),
        ...generateAccess(access_14),
        ...generateAccess(access_15),
        ...generateAccess(access_16),
        ...generateAccess(access_17),
        ...generateAccess(access_18),
        ...generateAccess(access_19),
        ...generateAccess(access_20),
        ...generateAccess(access_21),
        ...generateAccess(access_22),
        ...generateAccess(access_23),
        ...generateAccess(access_24),
        ...generateAccess(access_25),
        ...generateAccess(access_26),
        ...generateAccess(access_27),
        ...generateAccess(access_28),
        ...generateAccess(access_29),
        ...generateAccess(access_30),
        ...generateAccess(access_31),
        ...generateAccess(access_32),
        ...generateAccess(access_33),
        ...generateAccess(access_34),
        ...generateAccess(access_35),
        ...generateAccess(access_36),
        ...generateAccess(access_37),
        ...generateAccess(access_38),
        ...generateAccess(access_39),
        ...generateAccess(access_40),
        ...generateAccess(access_41),
        ...generateAccess(access_42),
        ...generateAccess(access_43),
        ...generateAccess(access_44),
        ...generateAccess(access_45),
        ...generateAccess(access_46),
        ...generateAccess(access_47),
        ...generateAccess(access_48),
        ...generateAccess(access_49),
        ...generateAccess(access_50),
        ...generateAccess(access_51),
        ...generateAccess(access_52),
        ...generateAccess(access_53),
        ...generateAccess(access_54),
        ...generateAccess(access_55),
        ...generateAccess(access_56),
}

export const AccessTree = [
  {
    "name": "/",
    "path": "/",
    "children": [
      {
        "name": "system",
        "path": "/system",
        "children": [
          {
            "name": "privilege",
            "path": "/system/privilege",
            "children": [
              {
                "name": "user",
                "path": "/system/privilege/user",
                "access": access_0
              },
              {
                "name": "reviewUser",
                "path": "/system/privilege/reviewUser",
                "access": access_1
              },
              {
                "name": "collectionUser",
                "path": "/system/privilege/collection-user",
                "access": access_2
              },
              {
                "name": "role",
                "path": "/system/privilege/role",
                "access": access_3
              },
              {
                "name": "path",
                "path": "/system/privilege/path",
                "access": access_4
              },
              {
                "name": "permission",
                "path": "/system/privilege/permission",
                "access": access_5
              },
              {
                "name": "group",
                "path": "/system/privilege/group",
                "access": access_6
              }
            ]
          },
          {
            "name": "noticeManage",
            "path": "/system/noticeManage",
            "access": access_7
          }
        ]
      },
      {
        "name": "userManage",
        "path": "/userManage",
        "children": [
          {
            "name": "userFeedback",
            "path": "/userManage/userFeedback",
            "access": access_8
          },
          {
            "name": "userList",
            "path": "/userManage/userList",
            "access": access_9
          },
          {
            "name": "userListDetail",
            "path": "/userManage/userListDetail",
            "access": access_10
          },
          {
            "name": "repaymentCode",
            "path": "/userManage/repaymentCode",
            "access": access_11
          }
        ]
      },
      {
        "name": "operation",
        "path": "/operation",
        "children": [
          {
            "name": "channelManage",
            "path": "/operation/channelManage",
            "access": access_12
          }
        ]
      },
      {
        "name": "collection",
        "path": "/collection",
        "children": [
          {
            "name": "my",
            "path": "/collection/my",
            "access": access_13
          },
          {
            "name": "allCase",
            "path": "/collection/allCase",
            "access": access_14
          },
          {
            "name": "caseDetail",
            "path": "/collection/my/caseDetail/:customerId",
            "access": access_15
          },
          {
            "name": "specialDivision",
            "path": "/collection/specialDivision",
            "access": access_16
          },
          {
            "name": "divisionRecord",
            "path": "/collection/divisionRecord",
            "access": access_17
          },
          {
            "name": "CollectionConfig",
            "path": "/collection/config",
            "children": [
              {
                "name": "RemissionPlanConfig",
                "path": "/collection/config/remissionPlanConfig",
                "access": access_18
              },
              {
                "name": "QueueManage",
                "path": "/collection/config/queueManage",
                "access": access_19
              },
              {
                "name": "GroupQueueManage",
                "path": "/collection/config/groupQueueManage",
                "access": access_20
              },
              {
                "name": "MessageTemplate",
                "path": "/collection/config/messageTemplate",
                "access": access_21
              }
            ]
          },
          {
            "name": "outCallsManage",
            "path": "/collection/outCallsManage",
            "children": [
              {
                "name": "providerConfig",
                "path": "/collection/outCallsManage/providerConfig",
                "access": access_22
              },
              {
                "name": "taskConfig",
                "path": "/collection/outCallsManage/taskConfig",
                "access": access_23
              },
              {
                "name": "taskAssociation",
                "path": "/collection/outCallsManage/taskAssociation",
                "access": access_24
              },
              {
                "name": "taskPush",
                "path": "/collection/outCallsManage/taskPush",
                "access": access_25
              },
              {
                "name": "callList",
                "path": "/collection/outCallsManage/callList",
                "access": access_26
              }
            ]
          },
          {
            "name": "outCallsRecords",
            "path": "/collection/outCallsRecords",
            "children": [
              {
                "name": "BLURecords",
                "path": "/collection/outCallsRecords/BLURecords",
                "access": access_27
              }
            ]
          },
          {
            "name": "ApproveCenter",
            "path": "/collection/ApproveCenter",
            "access": access_28
          },
          {
            "name": "workSchedule",
            "path": "/collection/workSchedule",
            "children": [
              {
                "name": "detail",
                "path": "/collection/workSchedule/detail",
                "access": access_29
              },
              {
                "name": "changeLog",
                "path": "/collection/workSchedule/changeLog",
                "access": access_30
              }
            ]
          },
          {
            "name": "mobiles",
            "path": "/collection/mobiles",
            "access": access_31
          },
          {
            "name": "contactMobiles",
            "path": "/collection/contactMobiles",
            "access": access_32
          },
          {
            "name": "blacklist",
            "path": "/collection/blacklist",
            "access": access_33
          },
          {
            "name": "routerTest",
            "path": "/collection/routerTest",
            "access": access_34
          },
          {
            "name": "monitor",
            "path": "/collection/monitor",
            "children": [
              {
                "name": "urgencyRate",
                "path": "/collection/monitor/urgencyRate",
                "access": access_35
              }
            ]
          }
        ]
      },
      {
        "name": "creditReview",
        "path": "/creditReview",
        "children": [
          {
            "name": "FirstReview",
            "path": "/creditReview/FirstReview",
            "children": [
              {
                "name": "auditReview",
                "path": "/creditReview/FirstReview/auditReview",
                "access": access_36
              },
              {
                "name": "auditedReview",
                "path": "/creditReview/FirstReview/auditedReview",
                "access": access_37
              },
              {
                "name": "manualAuditStatistics",
                "path": "/creditReview/FirstReview/manualAuditStatistics",
                "access": access_38
              }
            ]
          },
          {
            "name": "withdrawalReview",
            "path": "/creditReview/withdrawalReview",
            "children": [
              {
                "name": "auditReview",
                "path": "/creditReview/withdrawalReview/auditReview",
                "access": access_39
              },
              {
                "name": "auditedReview",
                "path": "/creditReview/withdrawalReview/auditedReview",
                "access": access_40
              },
              {
                "name": "withdrawalDetail",
                "path": "/creditReview/withdrawalReview/withdrawalDetail",
                "access": access_41
              },
              {
                "name": "withdrawalStatistics",
                "path": "/creditReview/withdrawalReview/withdrawalStatistics",
                "access": access_42
              }
            ]
          },
          {
            "name": "creditRecordManager",
            "path": "/creditReview/creditRecordManager",
            "access": access_43
          },
          {
            "name": "creditRecordDetail",
            "path": "/creditReview/creditRecordDetail",
            "access": access_44
          },
          {
            "name": "withdrawManager",
            "path": "/creditReview/withdrawManage",
            "access": access_45
          },
          {
            "name": "changeOrderStatus",
            "path": "/creditReview/changeOrderStatus",
            "access": access_46
          },
          {
            "name": "AntiFraud",
            "path": "/creditReview/AntiFraud",
            "access": access_47
          },
          {
            "name": "AntiFraudDetail",
            "path": "/creditReview/AntiFraud/detail",
            "access": access_48
          }
        ]
      },
      {
        "name": "developer",
        "path": "/developer",
        "children": [
          {
            "name": "indicatorManagement",
            "path": "/developer/indicatorManagement/",
            "children": [
              {
                "name": "indicationManage",
                "path": "/developer/indicatorManagement/indicationManage",
                "access": access_49
              },
              {
                "name": "modelManage",
                "path": "/developer/indicatorManagement/modelManage",
                "access": access_50
              },
              {
                "name": "datasourceManage",
                "path": "/developer/indicatorManagement/datasourceManage",
                "access": access_51
              },
              {
                "name": "scriptManage",
                "path": "/developer/indicatorManagement/scriptManage",
                "access": access_52
              },
              {
                "name": "groupManage",
                "path": "/developer/indicatorManagement/groupManage",
                "access": access_53
              },
              {
                "name": "indicatorGroupManage",
                "path": "/developer/indicatorManagement/indicatorGroupManage",
                "access": access_54
              }
            ]
          }
        ]
      },
      {
        "name": "loanManage",
        "path": "/loanManage",
        "children": [
          {
            "name": "loanRecords",
            "path": "/loanManage/loanRecords",
            "access": access_55
          }
        ]
      },
      {
        "name": "riskManage",
        "path": "/riskManage",
        "children": [
          {
            "name": "blacklist",
            "path": "/riskManage/blacklist",
            "access": access_56
          }
        ]
      }
    ]
  }
]

/** 保证accessId的前缀和路由同名
if (process.env.NODE_ENV === 'development') {
  (function checkAccess(routeTree: any[]) {
    let prefix = '';
    for (let i = 0; i < routeTree.length; i++) {
      const route = routeTree[i];
      if (route && route.children) {
        checkAccess(route.children);
      }
      if (route.access && route.access.id){
        const id = route.access.id;
        if (id.endsWith("!")){
          continue;
        }
        const accessStringArrays: string[] = id.split(".")
        if (accessStringArrays.length<2){
          throw new Error(`access.id: '${id}' 命名错误`);
        }
        accessStringArrays.pop();
        const thisPrefix = accessStringArrays.join('.')
        if (prefix){
          if (prefix !== thisPrefix){
            throw new Error(`access.id: '${id}' 命名错误`);
          }
        }else {
          prefix = thisPrefix;
        }
      }
    }
  })(AccessTree[0].children)
}
*/ 


export const AccessList = Object.values(AccessIds)
