export default {
  'menu.creditReview': '信审',
  'menu.creditReview.FirstReview': '一审',
  'menu.creditReview.FirstReview.auditReview': '待审核列表',
  'menu.creditReview.FirstReview.auditedReview': '已审核列表',
  'menu.creditReview.FirstReview.manualAuditStatistics': '人工审核统计',
  'menu.creditReview.creditRecordManager': '授信记录管理',
  'menu.creditReview.withdrawManager': '提现记录管理',
  'menu.userManage': '用户管理',
  'menu.userManage.userFeedback': '用户反馈',
  'menu.userManage.userList': '用户列表',
  'menu.creditReview.creditRecordDetail': '审核详情页',
  'menu.userManage.userListDetail': '用户详情页',
  'menu.loanManage': '放款管理',
  'menu.loanManage.loanRecords': '放款记录',
  'menu.developer.indicatorManagement.modelManage': '模型管理',
  'menu.system': '系统设置',
  'menu.system.privilege': '权限管理',
  'menu.system.privilege.user': '用户管理',
  'menu.system.privilege.reviewUser': '审核用户管理',
  'menu.system.privilege.collectionUser': '催收用户管理',
  'menu.system.privilege.group': '组管理',
  'menu.system.privilege.role': '角色管理',
  'menu.system.privilege.path': 'api管理',
  'menu.system.privilege.permission': '权限管理',
  'menu.collection': '催收系统',
  'menu.collection.my': '我的案件',
  'menu.collection.allCase': '全部案件',
  'menu.collection.caseDetail': '案件详情',
  'menu.collection.CollectionConfig': '配置管理',
  'menu.collection.CollectionConfig.QueueManage': '队列管理',
  'menu.collection.CollectionConfig.GroupQueueManage': '组队列管理',
  'menu.collection.CollectionConfig.MessageTemplate': '短信模板配置',
  'menu.riskManage': '风险管理',
  'menu.riskManage.blacklist': '黑名单管理',
  'menu.operation': '运营',
  'menu.operation.channelManage': '渠道管理',
  'menu.collection.mobiles': '本人号码拉取',
  'menu.collection.contactMobiles': '联系人手机号',
  'menu.collection.workSchedule': '催收排班',
  'menu.collection.workSchedule.detail': '排班详情',
  'menu.collection.workSchedule.changeLog': '排班变更日志',
  'menu.collection.blacklist': '黑名单',
  'menu.creditReview.withdrawalReview': '提现审核',
  'menu.creditReview.withdrawalReview.auditReview': '待审核列表',
  'menu.creditReview.withdrawalReview.auditedReview': '已审核列表',
  'menu.collection.outCallsManage': '外呼管理',
  'menu.collection.outCallsManage.providerConfig': '外呼供应商配置',
  'menu.collection.outCallsManage.taskConfig': '外呼任务配置',
  'menu.collection.outCallsManage.taskAssociation': '外呼任务关联',
  'menu.collection.outCallsManage.taskPush': '外呼任务推送',
  'menu.collection.outCallsManage.callList': '外呼名单',
  'menu.collection.outCallsManage.callRecords': '外呼记录',
  'menu.collection.outCallsManage.saiDuoCallList': '赛舵外呼列表',
  'menu.collection.outCallsRecords': '三方外呼记录',
  'menu.collection.outCallsRecords.BLURecords': 'blu外呼记录',
  'menu.collection.outCallsRecords.SaiDuoRecords': '赛舵外呼记录',
  'menu.creditReview.withdrawalReview.withdrawalDetail': '审核详情页',
  'menu.userManage.repaymentCode': '还款码查询',
  'menu.collection.monitor': '监控',
  'menu.collection.monitor.urgencyRate': '催回率监控',
  'menu.collection.specialDivision': '特殊分案',
  'menu.collection.divisionRecord': '分案记录',
  'menu.creditReview.AntiFraud': '反欺诈案调系统',
  'menu.creditReview.AntiFraudDetail': '反欺诈案调详情页',
  'menu.collection.CollectionConfig.RemissionPlanConfig': '减免方案配置',
  'menu.collection.ApproveCenter': '减免审批中心',
  'menu.collection.routerTest': '催收路线测试',
  'menu.creditReview.withdrawalReview.withdrawalStatistics': '提现人工审核统计',
  'menu.creditReview.changeOrderStatus': '变更订单状态',
  'menu.system.noticeManage': 'APP通用配置',
};
